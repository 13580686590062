<template>
	<Navbar />
	<NuxtPage />
	<Footer />
</template>

<script setup>
const config = useRuntimeConfig()
useSeoMeta({
	title: config.public.title,
	ogTitle: config.public.ogTitle,
	description: config.public.description,
	ogDescription: config.public.ogDescription,
	ogImage: config.public.ogImage,
})
</script>
