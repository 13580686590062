import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig()
	Sentry.init({
		app: nuxtApp.vueApp,
		dsn:
			process.env.ENV === 'local'
				? ''
				: 'https://64d000b52f8f89b1ad5bb4368900092a@o304000.ingest.sentry.io/4505631003115520',
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					'localhost',
					'^https:\\/\\/impro-amsterdam.nl/api/',
					'^https:\\/\\/impro-amsterdam.nl/gql/',
				],
				routingInstrumentation: Sentry.vueRouterInstrumentation(
					nuxtApp.$router as Router,
				),
			}),
			new Sentry.Replay(),
			new CaptureConsoleIntegration({
				// array of methods that should be captured
				// defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
				levels: ['error', 'assert', 'warn', 'info'],
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})

	return {
		provide: {
			sentrySetContext: Sentry.setContext,
			sentrySetUser: Sentry.setUser,
			sentrySetTag: Sentry.setTag,
			sentryAddBreadcrumb: Sentry.addBreadcrumb,
			sentryCaptureException: Sentry.captureException,
		},
	}
})
