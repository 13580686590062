import revive_payload_client_4sVQNw7RlN from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fontawesome_klhsrycjcK from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/plugins/fontawesome.js";
import plugin_1UohGbtF8v from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import csrf_jg2tPmhleV from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/plugins/csrf.ts";
import recaptcha_85gNSCNFUU from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/plugins/recaptcha.ts";
import sentry_client_shVUlIjFLk from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/plugins/sentry.client.ts";
import vue_awesome_paginate_l1b0wTlGPB from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/plugins/vue-awesome-paginate.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  fontawesome_klhsrycjcK,
  plugin_1UohGbtF8v,
  csrf_jg2tPmhleV,
  recaptcha_85gNSCNFUU,
  sentry_client_shVUlIjFLk,
  vue_awesome_paginate_l1b0wTlGPB
]