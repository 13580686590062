import { defineStore } from 'pinia'
import { APIConfig } from '~/services/types'

export const useApiConfigStore = defineStore({
	id: 'apiConfig',
	state: () => ({
		REST_ENDPOINT: '',
		GQL_ENDPOINT: '',
	}),
	actions: {
		setConfig(config: APIConfig) {
			this.REST_ENDPOINT = config.rest_endpoint
			this.GQL_ENDPOINT = config.gql_endpoint
		},
	},
})
