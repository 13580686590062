<template>
	<footer class="bg-white">
		<div
			class="mx-auto border-t border-gray-600/10 max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8"
		>
			<nav class="columns-2 flex justify-center space-x-12" aria-label="Footer">
				<div
					v-for="item in navigation.bottomNavigation"
					:key="item.name"
					class="pb-6"
				>
					<a
						:href="item.href"
						class="text-sm leading-6 text-gray-600 hover:text-gray-900"
						>{{ item.name }}</a
					>
				</div>
			</nav>
			<div class="mt-10 flex justify-center space-x-10">
				<a v-for="item in data?.socialNetworks" :href="item.link">
					<span class="sr-only">{{ item.socialNetwork }}</span>
					<font-awesome-icon
						:icon="['fab', item.socialNetwork.toLowerCase()]"
						class="footer-social"
					/>
				</a>
			</div>
			<p class="mt-10 text-center text-xs leading-5 text-gray-500">
				&copy; 2023 IMPRO Amsterdam. All rights reserved.
			</p>
		</div>
	</footer>
</template>

<script setup lang="ts">
import { useNavigationStore } from '~/store/navigation'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebook)

interface SocialNetwork {
	socialNetwork: string
	link: string
}

const query = gql`
	query footerQuery {
		socialNetworks {
			socialNetwork
			link
		}
	}
`

interface QueryResult {
	socialNetworks: SocialNetwork[]
}

const navigation = useNavigationStore()

const { data, error, pending } = await useAsyncQuery<QueryResult>(query, {})

if (error.value) {
	console.error('Error fetching footer data', JSON.stringify(error.value))
}
</script>
<style scoped>
.footer-social {
	color: gray;
}
</style>
