import { useApiConfigStore } from '~/store/apiConfigStore'
import { getAPIEndpoint, getGQLEndpoint } from '~/services/constants'

export default defineNuxtRouteMiddleware((to) => {
	// skip middleware on client side entirely
	if (process.client) return
	const apiConfigStore = useApiConfigStore()
	apiConfigStore.setConfig({
		rest_endpoint: getAPIEndpoint(),
		gql_endpoint: getGQLEndpoint(),
	})
})
