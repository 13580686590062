export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"href":"https://pretix.eu/demo/democon/widget/v1.css","type":"text/css","rel":"stylesheet"}],"style":[],"script":[{"hid":"pretix","src":"https://tickets.impro-amsterdam.nl/widget/v1.en.js","type":"text/javascript","async":true},{"hid":"fb_pixel","src":"/js/fb_pixel.js","type":"text/javascript","async":false}],"noscript":[{"children":"<img height=\"1\" width=\"1\" src=\"https://www.facebook.com/tr?id=1049447779365096&ev=PageView&noscript=1\" />"}],"bodyAttrs":{"class":"font-serif"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null