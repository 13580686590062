import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(VueReCaptcha, {
		siteKey: '6Lf5wTQnAAAAAAafoWJk_Uz12dvUzuQ2m258aDwM',
		loaderOptions: {
			autoHideBadge: false,
			explicitRenderParameters: {
				badge: 'bottomright',
			},
		},
	})
})
